<template>
  <v-theme-provider light id="cmddoc">
    <v-card
      elevation="4"
      :key="kdoc"
      v-if="editedItem.id > 0 || editedItem.id == -1"
    >
      <v-toolbar
        :disabled="save_disable"
        dense
        floating
        class="mt-n2 ml-n2"
        color="#73DDFF"
      >
        <v-row>
          <v-col cols="12" sd="12" md="12">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="solder_cmd"
                  v-if="
                    solder &&
                    ((tiertype <= 4 &&
                      editedItem.tot_liv < editedItem.tot_qte) ||
                      (tiertype > 4 && !editedItem.expire)) &&
                    editedItem.statut_id == '7' &&
                    (editedItem.can_update || $store.state.isadmin)
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="save_disable"
                >
                  <v-icon> mdi-cancel </v-icon>
                  {{ tiertype > 4 ? "Annuler" : "Solder" }}
                </v-btn>
              </template>
              <span>
                {{ (tiertype > 4 ? "Annuler " : "Solder ") + title }}</span
              >
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="receipt"
                  v-if="
                    editedItem.NbProduct > 0 &&
                    (((tiertype == 1 || tiertype == 3) &&
                      $store.state.auth.includes('02009')) ||
                      ((tiertype == 2 || tiertype == 4) &&
                        $store.state.auth.includes('02007')) ||
                      $store.state.isadmin) &&
                    tiertype <= 4 &&
                    editedItem.tot_liv < editedItem.tot_qte &&
                    editedItem.statut_id == '7' &&
                    editedItem.country_id == '13'
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="save_disable"
                >
                  <v-icon> mdi-plus </v-icon>
                  {{
                    tiertype == 1 || tiertype == 3 ? "Expedition" : "Reception"
                  }}
                </v-btn>
              </template>
              <span>{{
                tiertype == 1 || tiertype == 3
                  ? "Nouvelle expedition"
                  : "Nouvelle Reception"
              }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="receipt_sf"
                  v-if="
                    editedItem.NbService > 0 &&
                    (((tiertype == 2 || tiertype == 4) &&
                      $store.state.auth.includes('02021')) ||
                      ((tiertype == 2 || tiertype == 4) &&
                        $store.state.isadmin)) &&
                    tiertype <= 4 &&
                    editedItem.tot_liv < editedItem.tot_qte &&
                    editedItem.statut_id == '7'
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="save_disable"
                >
                  <v-icon> mdi-plus </v-icon>
                  {{ "Service Fait" }}
                </v-btn>
              </template>
              <span>{{ "Nouveau Service Fait" }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="valider_cmd"
                  v-if="
                    valider &&
                    editedItem.statut_id == '6' &&
                    detail_list.length > 0 &&
                    !edit &&
                    (editedItem.can_update || $store.state.isadmin)
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="save_disable"
                  ><v-icon> mdi-check-bold </v-icon>
                  Valider
                </v-btn>
              </template>
              <span>Valider Commande</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="amender_cmd"
                  v-if="
                    amender &&
                    editedItem.statut_id == '7' &&
                    tiertype <= 2 &&
                    editedItem.nbmvm == 0 &&
                    editedItem.nbdossier == 0
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="save_disable"
                >
                  <v-icon> mdi-file-replace </v-icon>
                  Amender
                </v-btn>
              </template>
              <span>Annuler et remplacer cette commande </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="deleteCmd"
                  v-if="
                    editer &&
                    editedItem.statut_id == '6' &&
                    !edit &&
                    (editedItem.can_update || $store.state.isadmin)
                  "
                  :disabled="
                    editedItem.nbdocs > 0 ||
                    detail_list.length > 0 ||
                    save_disable
                  "
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon> mdi-delete </v-icon>
                  Supprimer
                </v-btn>
              </template>
              <span>Supprimer</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="modifier"
                  v-if="
                    editer &&
                    editedItem.statut_id == '6' &&
                    !edit &&
                    (editedItem.can_update || $store.state.isadmin)
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="save_disable"
                  ><v-icon> mdi-pencil </v-icon>
                  Modifier
                </v-btn>
              </template>
              <span>Editer pour modification</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="cancel"
                  v-if="
                    editer &&
                    edit &&
                    (editedItem.can_update || $store.state.isadmin)
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="save_disable"
                  ><v-icon> mdi-close </v-icon> Annuler</v-btn
                >
              </template>
              <span>Annuler les Modifications</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="save"
                  v-if="
                    editer &&
                    edit &&
                    (editedItem.can_update || $store.state.isadmin)
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="save_disable"
                  ><v-icon> mdi-content-save-outline </v-icon>
                  Enregistrer
                </v-btn>
              </template>
              <span>Enregistrer les modifications</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="list_avenants"
                  v-if="
                    (tiertype == 3 || tiertype == 4) &&
                    editedItem.statut_id == '7' &&
                    avenants.length > 0 &&
                    !edit &&
                    (editedItem.can_update || $store.state.isadmin)
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="save_disable"
                  ><v-icon> mdi-file-table-outline </v-icon>
                  Avenants
                </v-btn>
              </template>
              <span>Contrat Initial et Avenants </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="printCmd"
                  v-if="editedItem.statut_id > '6'"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="save_disable"
                  ><v-icon> mdi-printer </v-icon></v-btn
                >
              </template>
              <span>Imprimer</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-if="
                    editedItem.id > 0 &&
                    ($store.state.auth.includes('01061') ||
                      $store.state.isadmin) &&
                    (editedItem.can_update || $store.state.isadmin)
                  "
                  @click="OpenDocList"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="save_disable"
                >
                  <v-icon> mdi-paperclip </v-icon>
                </v-btn>
              </template>
              <span>Documents Attachés</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  @click="unlock"
                  v-if="
                    ($store.state.auth.includes('01068') ||
                      $store.state.isadmin) &&
                    (editedItem.nbmvm == 0 || $store.state.isadmin) &&
                    editedItem.statut_id == '7' &&
                    editedItem.avenants.length == 0 &&
                    !edit &&
                    (editedItem.can_update || $store.state.isadmin) &&
                    editedItem.nbdossier == 0
                  "
                  v-bind="attrs"
                  v-on="on"
                  :disabled="save_disable"
                  ><v-icon> mdi-lock-open-variant </v-icon>
                </v-btn>
              </template>
              <span>Déverrouiller</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  v-if="
                    editedItem.id > 0 &&
                    ($store.state.auth.includes('01060') ||
                      $store.state.isadmin) &&
                    (editedItem.can_update || $store.state.isadmin)
                  "
                  @click.stop="drawer = !drawer"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-clipboard-text-clock-outline </v-icon>
                </v-btn>
              </template>
              <span>Historique Document</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  v-if="
                    editedItem.id > 0 &&
                    editer &&
                    (editedItem.can_update || $store.state.isadmin)
                  "
                  @click.stop="drawer2 = !drawer2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-notebook-edit-outline </v-icon>
                </v-btn>
              </template>
              <span>Suivi Commande</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-progress-linear
        v-if="progress || loading"
        indeterminate
        color="light-green darken-4"
        height="10"
        striped
      ></v-progress-linear>
      <v-card class="px-4 scroll" max-height="850">
        <v-card-text>
          <v-row>
            <v-col cols="12" sd="3" md="3" align-self="start">
              <img :src="logo" alt="Company Logo" />
            </v-col>
            <v-col
              cols="12"
              sm="2"
              md="2"
              v-if="edit && !alert && confidentiel"
            >
              <v-checkbox
                v-model="editedItem.isprivate"
                label="confidentiel "
                :readonly="!edit"
                dense
                @change="cmpt++"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sd="3" md="4">
              <v-alert
                v-model="alert"
                :type="type_alert"
                class="multi-line"
                width="380"
                elevation="4"
                dismissible
                >{{ message }}</v-alert
              >
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-row>
            <v-col></v-col>
          </v-row>
          <v-form ref="form" v-model="valid" lazy-validation class="mt-6">
            <v-row>
              <v-col cols="12" sd="6" md="6" lg="6">
                <v-row>
                  <v-col
                    cols="12"
                    sd="6"
                    md="6"
                    class="text-left"
                    v-if="
                      (tiertype == 3 || tiertype == 4) &&
                      edit &&
                      editedItem.id < 0
                    "
                  >
                    <v-select
                      dense
                      :items="[
                        { id: '0', libelle: 'Contrat' },
                        { id: '1', libelle: 'Avenant' },
                      ]"
                      item-text="libelle"
                      item-value="id"
                      v-model="editedItem.c_type"
                      label="Type de Contrat"
                      :readonly="!edit"
                      @input="cmpt++"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sd="12"
                    md="12"
                    class="text-left"
                    v-if="
                      (((tiertype == 3 || tiertype == 4) &&
                        editedItem.c_type == '1') ||
                        ((tiertype == 1 || tiertype == 2) &&
                          commandes.length > 0)) &&
                      edit
                    "
                  >
                    <v-select
                      dense
                      :items="commandes"
                      :item-text="
                        (item) =>
                          item.nocmd +
                          (item.cmd_ref ? ' (' + item.cmd_ref + ') ' : '') +
                          (item.tier_name ? '-' + item.tier_name : '')
                      "
                      item-value="id"
                      v-model="editedItem.contrat_id"
                      :label="
                        tiertype == 3 || tiertype == 4
                          ? 'Contrat de base'
                          : 'Convention'
                      "
                      :readonly="!edit || editedItem.cmddetails.length > 0"
                      @input="cmpt++"
                      @change="contrat_select"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sd="11" md="11">
                    <v-row>
                      <v-col
                        cols="11"
                        sm="11"
                        md="11"
                        lg="11"
                        v-if="edit && editedItem.c_type == '0'"
                      >
                        <cursorselect
                          :Qsearch="tierscursor"
                          :Qresp="'tiers_cursor'"
                          :value="editedItem.tier_id"
                          :text_fields="[
                            'code_client',
                            'code_fournisseur',
                            'nom',
                          ]"
                          :variableadd="variableadd"
                          @change="tierChange"
                          :label="
                            tiertype == 1 || tiertype == 3 || tiertype == 5
                              ? 'Client'
                              : 'Fournisseur'
                          "
                          :rules="true"
                          :readonly="
                            !modify || editedItem.cmddetails.length > 0 || !edit
                          "
                        >
                        </cursorselect>
                      </v-col>

                      <v-col cols="12" sm="1" md="1" lg="1">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-if="
                                modify &&
                                edit_tier &&
                                edit &&
                                editedItem.cmddetails.length == 0 &&
                                editedItem.c_type == '0'
                              "
                              text
                              @click.stop="newtier"
                              class="mt-1"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon> mdi-plus </v-icon>
                            </v-btn>
                          </template>
                          <span
                            >Ajouter
                            {{
                              tiertype == 1 || tiertype == 3 || tiertype == 5
                                ? "Client"
                                : "Fournisseur"
                            }}</span
                          >
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="12" sd="2" md="4" class="text-left" v-if="!edit">
                    {{
                      tiertype == 1 || tiertype == 3 || tiertype == 5
                        ? "Client"
                        : "Fournisseur"
                    }}
                  </v-col>

                  <v-col
                    cols="12"
                    sd="2"
                    md="8"
                    class="text-left h5"
                    v-if="!edit"
                  >
                    {{ editedItem.tier_name }}
                  </v-col>

                  <v-col cols="12" sd="2" md="4"> Code </v-col>
                  <v-col cols="12" sd="2" md="8">
                    <a @click="gotier" v-if="consult_tier">
                      {{ editedItem.code_tier }}
                    </a>
                    <div v-else>
                      {{ editedItem.code_tier }}
                    </div>
                  </v-col>

                  <v-col cols="12" sd="2" md="4"> Adresse</v-col>
                  <v-col cols="12" sd="2" md="8">
                    {{ editedItem.tier ? editedItem.tier.adresse : "" }}
                  </v-col>

                  <v-col
                    cols="12"
                    sd="2"
                    md="4"
                    v-if="editedItem.country_id == 13"
                  >
                    R.C. N°</v-col
                  >
                  <v-col
                    cols="12"
                    sd="2"
                    md="8"
                    v-if="editedItem.country_id == 13"
                  >
                    {{ editedItem.tier ? editedItem.tier.RC : "" }}
                  </v-col>

                  <v-col
                    cols="12"
                    sd="2"
                    md="4"
                    v-if="editedItem.country_id == 13"
                    >N° Article</v-col
                  >
                  <v-col
                    cols="12"
                    sd="2"
                    md="8"
                    v-if="editedItem.country_id == 13"
                  >
                    {{ editedItem.tier ? editedItem.tier.ART : "" }}
                  </v-col>

                  <v-col
                    cols="12"
                    sd="2"
                    md="4"
                    v-if="editedItem.country_id == 13"
                    >Id Fiscal</v-col
                  >
                  <v-col
                    cols="12"
                    sd="2"
                    md="8"
                    v-if="editedItem.country_id == 13"
                  >
                    {{ editedItem.tier ? editedItem.tier.NIF : "" }}
                  </v-col>
                  <v-col
                    cols="12"
                    sd="2"
                    md="8"
                    v-if="livs.length > 0 && tiertype <= 2 && edit"
                  >
                    <v-checkbox
                      v-model="reg_type"
                      label="BC de Régularisation"
                      dense
                      :readonly="!reg_type && detail_list.length > 0"
                      @change="cmpt++"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="12"
                    v-if="reg_type && livs.length > 0 && edit"
                  >
                    <v-autocomplete
                      v-model="livs_select"
                      :items="livs"
                      :item-text="
                        (item) =>
                          item.nobon +
                          ' du ' +
                          datefr(item.opr_date) +
                          ' Montant: ' +
                          numberWithSpace(
                            item.mont_ttc ? item.mont_ttc.toFixed(2) : '0.00'
                          )
                      "
                      item-value="id"
                      :label="'Bons de reception'"
                      :rules="[
                        (v) =>
                          livs_select.length > 0 ||
                          !reg_type ||
                          'Bons de reception obligatoire',
                      ]"
                      :readonly="!edit"
                      multiple
                      small-chips
                      dense
                      clearable
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sd="2" md="6" lg="6">
                <v-row no-gutters>
                  <v-col cols="12" sd="9" md="9">
                    <h1>{{ formTitle }}</h1>
                    <br />
                    <strong> Date : {{ datefr(editedItem.sign_date) }}</strong>
                    <br />
                    <strong v-if="!edit && editedItem.pfm_id">
                      Selon la proforma N° :
                      <a @click="goto_proforma">{{
                        editedItem.nopfm
                      }}</a></strong
                    >
                    <br />
                  </v-col>
                  <v-col
                    cols="12"
                    sd="3"
                    md="3"
                    class="text-left"
                    v-if="edit && tiertype == 2 && !reg_type"
                  >
                    <v-select
                      dense
                      :items="[
                        { id: '0', libelle: 'Fichier' },
                        { id: '1', libelle: 'Besoins' },
                      ]"
                      item-text="libelle"
                      item-value="id"
                      v-model="editedItem.source_id"
                      label="Liste des Produits"
                      :readonly="!edit || editedItem.cmddetails.length > 0"
                      @input="cmpt++"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sd="3"
                    md="3"
                    v-if="!edit && tiertype == 2 && !reg_type"
                  >
                    Source :
                    {{ editedItem.source_id == "0" ? "Fichier" : "Besoins" }}
                  </v-col>
                  <v-col cols="12" sd="12" md="12" v-if="!edit">
                    Reférence : {{ editedItem.cmd_ref }}
                  </v-col>
                  <v-col cols="12" sd="12" md="8" v-if="!edit && tiertype > 2">
                    Signataire : {{ editedItem.sign_by }}
                  </v-col>
                  <v-col
                    cols="12"
                    sd="12"
                    md="8"
                    v-if="!edit && tiertype <= 2 && editedItem.contrat_id"
                  >
                    Selon Convention N° :
                    {{ editedItem.contrat }}
                  </v-col>
                  <v-col cols="12" sd="12" md="8" v-if="!edit && tiertype > 4">
                    Prix Modifiable :
                    {{ editedItem.price_modif == 1 ? "Oui" : "Non" }}
                  </v-col>
                  <v-col cols="12" sd="12" md="12" lg="12">
                    <v-row dense v-if="edit">
                      <v-col cols="12" sd="6" md="6">
                        <v-text-field
                          autocomplete="off"
                          dense
                          class="inputPrice"
                          v-model="editedItem.cmd_ref"
                          label="Reférence"
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        v-if="editedItem.c_type == '0'"
                      >
                        <v-text-field
                          dense
                          v-model="editedItem.label"
                          :label="
                            'Libelle ' +
                            (tiertype == 1 || tiertype == 2
                              ? 'Commande'
                              : tiertype == 5 || tiertype == 6
                              ? 'Convention'
                              : 'Contrat')
                          "
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <datepicker
                          :label="'Date '"
                          v-model="editedItem.sign_date"
                          :rules="[(v) => !!v || 'Date obligatoire']"
                          :edit="true"
                          @change="nbjour"
                        ></datepicker>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" v-if="tiertype > 2">
                        <datepicker
                          :label="'Entree en vigueur'"
                          v-model="editedItem.date_cmd"
                          :rules="[
                            (v) => !!v || 'Date obligatoire',
                            (v) =>
                              !v ||
                              editedItem.date_cmd >= editedItem.sign_date ||
                              'Date Incorrecte',
                            (v) =>
                              !v ||
                              (duedate
                                ? editedItem.date_cmd <= duedate
                                : true) ||
                              'Date Incorrecte',
                          ]"
                          :edit="edit"
                          @change="addDays"
                          :date_max="null"
                        ></datepicker>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" v-if="tiertype > 1">
                        <v-text-field
                          autocomplete="off"
                          class="inputPrice"
                          dense
                          v-model="editedItem.sign_by"
                          label="Signé par"
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sd="6" md="6" v-if="!reg_type">
                        <datepicker
                          label="Date Livraison
                        "
                          v-model="duedate"
                          :edit="edit"
                          @change="nbjour"
                          :rules="[
                            (v) => !!v || tiertype <= 4 || 'Date obligatoire',
                          ]"
                          :key="kdd"
                          :date_max="null"
                        ></datepicker>
                      </v-col>
                      <v-col cols="12" sd="6" md="6" v-if="!reg_type">
                        <v-text-field
                          autocomplete="off"
                          type="number"
                          dense
                          class="inputPrice"
                          v-model.number="editedItem.delai"
                          :label="tiertype <= 4 ? 'Jours' : 'Durrée (Mois)'"
                          :readonly="!edit"
                          @input="addDays"
                          hide-spin-buttons
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sd="6" md="6" class="text-left">
                        <v-select
                          dense
                          :items="modes_list"
                          item-text="mode"
                          item-value="id"
                          v-model="editedItem.modepaiement"
                          label="Mode de Paiement"
                          :readonly="!edit"
                          @input="cmpt++"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sd="6" md="6" class="text-left">
                        <v-select
                          dense
                          :items="modalites"
                          item-text="libelle"
                          item-value="id"
                          v-model="editedItem.modalite_id"
                          label="Modalité de Paiement"
                          :readonly="!edit"
                          @input="cmpt++"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="2" md="2" v-if="tiertype > 4">
                        <v-checkbox
                          v-model="editedItem.price_modif"
                          label="Prix Modifiable "
                          :readonly="!edit"
                          dense
                          @change="cmpt++"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        sd="4"
                        md="4"
                        v-if="
                          editedItem.country_id != 13 && editedItem.country_id
                        "
                      >
                        <v-autocomplete
                          :items="currencies"
                          item-text="name"
                          item-value="id"
                          dense
                          v-model="editedItem.currency_id"
                          label="Devise"
                          :rules="[(v) => !!v || 'Devise obligatoire']"
                          :readonly="!modify"
                          @input="devise_change"
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        sd="3"
                        md="3"
                        class="text-left"
                        v-if="
                          editedItem.country_id != 13 && editedItem.country_id
                        "
                      >
                        <v-select
                          dense
                          :items="modestransports"
                          item-text="mode"
                          item-value="id"
                          v-model="editedItem.modetransport"
                          label="Mode de Transport"
                          :rules="[
                            (v) => !!v || 'Mode de Transport obligatoire',
                          ]"
                          :readonly="!edit"
                          @input="cmpt++"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sd="3"
                        md="3"
                        class="text-left"
                        v-if="
                          editedItem.country_id != 13 && editedItem.country_id
                        "
                      >
                        <v-select
                          dense
                          :items="allincotermes"
                          item-text="libelle"
                          item-value="id"
                          v-model="editedItem.incoterme"
                          label="Incoterm"
                          :rules="[(v) => !!v || 'Incoterm obligatoire']"
                          :readonly="!edit"
                          :disabled="!editedItem.modetransport"
                          @input="cmpt++"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sd="3"
                        md="3"
                        class="text-left"
                        v-if="
                          editedItem.country_id != 13 && editedItem.country_id
                        "
                      >
                        <v-select
                          dense
                          :items="ports_alg"
                          item-text="libelle"
                          item-value="id"
                          v-model="editedItem.port_id"
                          label="Lieu de débarquement"
                          :readonly="!edit"
                          :rules="[
                            (v) => !!v || 'Lieu de débarquement obligatoire',
                          ]"
                          :disabled="
                            !editedItem.modetransport ||
                            editedItem.modetransport >= '3'
                          "
                          @input="cmpt++"
                        ></v-select>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        v-if="
                          editedItem.country_id && editedItem.country_id != 13
                        "
                      >
                        <v-text-field
                          autocomplete="off"
                          class="inputPrice"
                          type="number"
                          dense
                          v-model.number="editedItem.mont_transport"
                          :label="'Frêt (' + editedItem.devise + ')'"
                          hide-spin-buttons
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="3"
                        md="3"
                        v-if="tiertype <= 4 && !reg_type"
                      >
                        <v-text-field
                          autocomplete="off"
                          class="inputPrice"
                          type="number"
                          dense
                          v-model.number="editedItem.avance"
                          label="Avance(%)"
                          :rules="[numberRule]"
                          hide-spin-buttons
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        v-if="tiertype <= 4 && !reg_type"
                      >
                        <v-text-field
                          autocomplete="off"
                          class="inputPrice"
                          type="number"
                          dense
                          v-model.number="editedItem.tol_qte"
                          label="Tolérence Livraison(%)"
                          :rules="[numberRule]"
                          hide-spin-buttons
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        v-if="(tiertype == 1 || tiertype == 3) && !reg_type"
                      >
                        <v-text-field
                          autocomplete="off"
                          class="inputPrice"
                          type="number"
                          dense
                          v-model.number="editedItem.tol_rcpt"
                          label="Tolérence Reception(%)"
                          :rules="[numberRule]"
                          hide-spin-buttons
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="3"
                        md="3"
                        v-if="tiertype == 3 || tiertype == 4"
                      >
                        <v-checkbox
                          v-model="editedItem.caution_av"
                          label="Caution Avance "
                          :readonly="!edit"
                          dense
                          :disabled="!editedItem.avance"
                          @change="cmpt++"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="3"
                        md="3"
                        v-if="tiertype == 3 || tiertype == 4"
                      >
                        <v-text-field
                          autocomplete="off"
                          class="inputPrice"
                          type="number"
                          dense
                          v-model.number="editedItem.garantie"
                          label="Garantie(%)"
                          :rules="[numberRule]"
                          hide-spin-buttons
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="3"
                        md="3"
                        v-if="tiertype == 3 || tiertype == 4"
                      >
                        <v-checkbox
                          v-model="editedItem.caution_bfe"
                          label="Caution BFE "
                          :readonly="!edit"
                          dense
                          :disabled="!editedItem.garantie"
                          @change="cmpt++"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                        md="4"
                        v-if="tiertype == 3 || tiertype == 4"
                      >
                        <v-text-field
                          autocomplete="off"
                          class="inputPrice"
                          type="number"
                          dense
                          v-model.number="editedItem.pr"
                          label="Pénalité Retard(%)"
                          :rules="[numberRule]"
                          hide-spin-buttons
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        v-if="tiertype == 3 || tiertype == 4"
                      >
                        <v-checkbox
                          v-model="editedItem.caution_pr"
                          label="Caution Pénalité Retard "
                          :readonly="!edit"
                          dense
                          :disabled="!editedItem.pr"
                          @change="cmpt++"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        v-if="
                          tiertype == 3 ||
                          (tiertype == 4 && editedItem.caution_pr)
                        "
                      >
                        <datepicker
                          :label="'Date Caution Pénalité Retard'"
                          v-model="editedItem.date_pr"
                          :rules="[
                            (v) => !!v || 'Date obligatoire',
                            (v) =>
                              !v ||
                              editedItem.date_pr >= editedItem.date_cmd ||
                              'Date Incorrecte',
                            (v) =>
                              !v ||
                              (duedate
                                ? editedItem.date_pr <= duedate
                                : true) ||
                              'Date Incorrecte',
                          ]"
                          :edit="edit"
                          :date_max="null"
                        ></datepicker>
                      </v-col>
                      <v-col
                        cols="12"
                        sd="12"
                        md="12"
                        lg="12"
                        v-if="tiertype > 2"
                      >
                        <v-autocomplete
                          :items="agences_tier"
                          item-value="id"
                          item-text="agence"
                          v-model="editedItem.bank_id"
                          label="Banque"
                          :readonly="!edit"
                          @input="cmpt++"
                          dense
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="1"
                        md="12"
                        v-if="categorie_list.length > 1 && tiertype <= 4"
                      >
                        <v-select
                          ref="categories"
                          :items="categorie_list"
                          v-model="editedItem.tier_categorie_id"
                          item-text="categorie"
                          item-value="categorie_id"
                          label="Categorie"
                          :readonly="!edit"
                          :rules="[(v) => !!v || 'Categorie obligatoire']"
                          dense
                        >
                          <template v-slot:selection="{ item }">
                            <v-chip :color="item.color" small>
                              <span>{{ item.categorie }}</span>
                            </v-chip>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-chip :color="item.color" small>
                              <span>{{ item.categorie }}</span>
                            </v-chip>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="3"
                        md="3"
                        v-if="
                          (tiertype == 1 || tiertype == 3 || tiertype == 5) &&
                          editedItem.country_id == 13
                        "
                      >
                        <v-checkbox
                          v-model="editedItem.exe_tva"
                          label="Exonoré de la TVA "
                          :readonly="!edit"
                          class="pa-1"
                          dense
                          @change="cmpt++"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="3"
                        md="3"
                        v-if="
                          $store.state.structures.filter(
                            (elm) => elm.type_id == 1
                          ).length > 1
                        "
                      >
                        <v-checkbox
                          v-model="editedItem.centrale"
                          label="Centralisée "
                          :readonly="!edit"
                          class="pa-1"
                          dense
                          @change="cmpt++"
                        ></v-checkbox>
                      </v-col>

                      <v-col
                        cols="12"
                        sd="12"
                        md="12"
                        class="text-left"
                        v-if="tiertype <= 2"
                      >
                        <v-text-field
                          dense
                          v-model="editedItem.notes"
                          label="Notes"
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sd="12" md="12" class="text-left">
                        <v-text-field
                          dense
                          v-model="editedItem.comment"
                          label="Commentaire"
                          :readonly="!edit"
                          @input="cmpt++"
                          @focus="$event.target.select()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col cols="12" sd="12" md="12" v-if="!edit">
              <listitems
                :list="detail_list"
                :title="'Produits'"
                :headers="products_headers"
                :filename="'Produits'"
                :sheetname="'Produits'"
                @open="OpenProductForm"
                @btn_click="OpenProductForm"
                :master="true"
                :showedit="false"
                :key="klist"
                :add="
                  editer &&
                  !reg_type &&
                  editedItem.statut_id == '6' &&
                  editedItem.id > 0
                "
                :Update="
                  editer &&
                  !reg_type &&
                  editedItem.statut_id == '6' &&
                  editedItem.id > 0
                "
                :del="
                  editer &&
                  !reg_type &&
                  editedItem.statut_id == '6' &&
                  editedItem.id > 0
                "
                :elevation="0"
                @delete="deleteitem"
                :ipg="ipg"
                @options="send_option"
              >
              </listitems>
            </v-col>
          </v-row>

          <v-row no-gutters v-if="!edit">
            <v-col cols="12" sd="6" md="8">
              <v-row no-gutters>
                <v-col
                  cols="12"
                  sd="6"
                  md="12"
                  class="text-left"
                  v-if="tiertype <= 4 && !reg_type"
                >
                  DELAI DE LIVRAISON

                  {{
                    editedItem.delai
                      ? editedItem.delai +
                        " jours (" +
                        datefr(editedItem.date_limite) +
                        ")"
                      : ""
                  }}
                </v-col>
                <v-col cols="12" sm="6" md="12" v-if="editedItem.garantie">
                  Retenue Garantie :
                  {{
                    editedItem.garantie +
                    " % " +
                    (editedItem.caution_bfe ? " + Caution" : "")
                  }}
                </v-col>
                <v-col cols="12" sm="6" md="12" v-if="editedItem.avance">
                  Avance :
                  {{
                    editedItem.avance +
                    " % " +
                    (editedItem.caution_av
                      ? " + Caution"
                      : "(" +
                        numberWithSpace(
                          ((editedItem.avance * grandTotal) / 100).toFixed(2)
                        ) +
                        ")")
                  }}
                </v-col>
                <v-col cols="12" sm="6" md="12" v-if="editedItem.pr">
                  Pénalité de Retard :
                  {{
                    editedItem.pr +
                    " % " +
                    (editedItem.caution_av ? " + Caution" : "")
                  }}
                </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="12"
                  class="text-left"
                  v-if="editedItem.label"
                >
                  LIBELLE COMMANDE :
                  {{ editedItem.label }}
                </v-col>
                <v-col cols="12" sd="6" md="12" class="text-left">
                  MODALITE DE PAIEMENT :
                  {{ modalite }}
                </v-col>
                <v-col cols="12" sd="6" md="12" class="text-left">
                  MODE DE PAIEMENT :
                  {{ mode }}
                </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="12"
                  class="text-left"
                  v-if="editedItem.modetransport && editedItem.country_id != 13"
                >
                  Mode de Transport :
                  {{ transport }}
                </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="12"
                  class="text-left"
                  v-if="editedItem.port_id && editedItem.country_id != 13"
                >
                  Port de débarquement :
                  {{ port }}
                </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="12"
                  class="text-left"
                  v-if="editedItem.incoterme && editedItem.country_id != 13"
                >
                  INCOTERM :
                  {{ editedItem.incoterme }}
                </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="12"
                  class="text-left"
                  v-if="tiertype <= 4 && !reg_type"
                >
                  Tolérence Livraison(%) :
                  {{ editedItem.tol_qte ? editedItem.tol_qte : "0" }}
                </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="12"
                  class="text-left"
                  v-if="(tiertype == 1 || tiertype == 3) && !reg_type"
                >
                  Tolérence Reception(%) :
                  {{ editedItem.tol_rcpt ? editedItem.tol_rcpt : "0" }}
                </v-col>
                <v-col cols="12" sd="6" md="12" class="text-left">
                  Notes : {{ editedItem.notes }}
                </v-col>
                <!-- <v-col
                  cols="12"
                  sd="6"
                  md="12"
                  class="text-left"
                  v-if="reg_type"
                >
                  NB : Bon de Commande régularisation
                </v-col> -->
                <v-col cols="12" sd="6" md="12" class="text-left">
                  Comment : {{ editedItem.comment }}
                </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="12"
                  class="text-left"
                  v-if="code != 1"
                >
                  Etablit Par : {{ editedItem.contact }}
                </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="12"
                  v-if="editedItem.centrale == 1"
                  class="text-left"
                >
                  Commande Centralisée
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sd="6" md="4">
              <v-row no-gutters v-if="tiertype <= 4">
                <v-col cols="12" sd="6" md="4">
                  {{ editedItem.country_id == 13 ? "Total H.T" : "Montant" }}
                </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="8"
                  class="text-bold nowrap text-right pa-1"
                >
                  {{ numberWithSpace(decimalDigits(subTotal)) }}
                </v-col>
              </v-row>
              <v-row no-gutters v-if="editedItem.mont_transport">
                <v-col cols="12" sd="6" md="4"> Frêt </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="8"
                  class="text-bold nowrap text-right pa-1"
                  v-if="tiertype <= 4"
                >
                  {{
                    numberWithSpace(decimalDigits(editedItem.mont_transport))
                  }}
                </v-col>
              </v-row>

              <v-row v-for="(tax, taux) in taxTotal" :key="taux" no-gutters>
                <v-col cols="12" sd="6" md="4" v-if="tiertype <= 4">
                  T.V.A {{ tax.taux }} %
                </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="8"
                  class="text-right pa-1"
                  v-if="tiertype <= 4"
                >
                  {{ numberWithSpace(decimalDigits(tax.tva)) }}
                </v-col>
              </v-row>
              <v-row v-if="taxTotal.length > 1 && tiertype <= 4" no-gutters>
                <v-col cols="12" sd="6" md="4"> Total T.V.A </v-col>
                <v-col cols="12" sd="6" md="8" class="text-right pa-1">
                  {{ numberWithSpace(decimalDigits(sumtaxTotal("tva"))) }}
                </v-col>
              </v-row>
              <v-row
                v-if="
                  subTotal > 0 &&
                  sumtaxTotal('tva') == 0 &&
                  editedItem.country_id == 13 &&
                  tiertype <= 4
                "
                no-gutters
              >
                <v-col cols="12" sd="6" md="4" v-if="tiertype <= 4">
                  T.V.A</v-col
                >
                <v-col
                  cols="12"
                  sd="6"
                  md="8"
                  class="text-bold text-right pa-1"
                  v-if="tiertype <= 4"
                >
                  EXONORE
                </v-col>
              </v-row>

              <v-row no-gutters v-if="tiertype <= 4">
                <v-col cols="12" sd="6" md="4"
                  >{{
                    editedItem.country_id == 13
                      ? "Total T.T.C :"
                      : "Total " + " (" + editedItem.devise + ")"
                  }}
                </v-col>
                <v-col
                  cols="12"
                  sd="6"
                  md="8"
                  class="text-bold nowrap text-right pa-1"
                  v-if="tiertype <= 4"
                >
                  {{ numberWithSpace(decimalDigits(grandTotal)) }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <template>
            <confirmdialog
              ref="confirm"
              :is_motif="is_motif"
              @motif="get_motif"
            />
          </template>

          <v-snackbar
            v-model="snackbar"
            :timeout="snackbar_timeout"
            top
            :color="snackbar_color"
          >
            {{ snackbar_text }}
          </v-snackbar>

          <cmddetailform
            :item="product"
            :items="detail_list"
            :readonly="editedItem.statut_id != '6'"
            :tiertype="tiertype"
            :key="dkey"
            :showForm="!isDetailClosed"
            :proforma="false"
            @close="closeDetailForm"
            :tier_type_prix="tier_type_prix"
            :units="units.filter((elm) => elm.uom_type == 'm')"
            :qte_prix="qte_prix"
            :cmd="editedItem"
            :products_categories="products_categories"
            :tier_products="tier_products"
            :cnv_list="cnv_list"
            :price_edit="price_edit"
          >
          </cmddetailform>

          <filelist
            :item="editedItem"
            :showform="!isDocListClose"
            :key="fdocl"
            @close="isDocListClose = true"
            :editer="editer"
            :doc_type="2"
            :auth="auth"
          >
          </filelist>
          <formlist
            :title="'Avenants'"
            :list="avenants"
            :showform="!isListClose"
            :listheaders="avenantsheaders"
            :key="fl"
            @close="isListClose = true"
            width="800px"
          >
          </formlist>
          <timeline
            :document_type="2"
            :document_id="parseInt(editedItem.id)"
            v-model="drawer"
            :operations="operations"
            :doc_type="'Commande'"
            :doc_code="editedItem.nocmd"
          >
          </timeline>
          <v-navigation-drawer v-model="drawer2" absolute temporary width="600">
            <comments
              :title="'Suivi Commande'"
              :comments="comments"
              :document_type="2"
              :doc_id="parseInt(editedItem.id)"
            ></comments>
          </v-navigation-drawer>
        </v-card-text>
      </v-card>
    </v-card>
  </v-theme-provider>
</template>
<script>
import TIERS_CURSOR from "../graphql/Commande/TIERS_CURSOR.gql";
import DELETE_PRODUIT from "../graphql/Commande/DELETE_PRODUIT.gql";
import DELETE_CMD from "../graphql/Commande/DELETE_CMD.gql";
import CREATE_CMD from "../graphql/Commande/CREATE_CMD.gql";
import UPDATE_CMD from "../graphql/Commande/UPDATE_CMD.gql";
import UPDATE_PFM from "../graphql/Proforma/UPDATE_PFM.gql";
import SOLDER_ORDER from "../graphql/Production/SOLDER_ORDER.gql";
import UPDATE_MODIF from "../graphql/UPDATE_MODIF.gql";
import CREATE_CMD_DETAIL from "../graphql/Proforma/CREATE_CMD_DETAIL.gql";
import UPDATE_MVM_CMD from "../graphql/Commande/UPDATE_MVM_CMD.gql";
import UPDATE_PRIX_ACHAT from "../graphql/Commande/UPDATE_PRIX_ACHAT.gql";
import { doc_template } from "print/template.js";
import { datefr } from "@/utils/functions.js";

import ALLDEMANDES_DETAIL from "../graphql/Demande/ALLDEMANDES_DETAIL.gql";

import {
  myLogo,
  myName,
  myIF,
  myRC,
  myART,
  grt,
  source,
  confidentiel,
  code,
} from "print/data.js";

export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    cmddetailform: () => import("./CmdDetailForm.vue"),
    filelist: () => import("./FileList.vue"),
    listitems: () => import("./ListItems.vue"),
    formlist: () => import("../components/FormList.vue"),
    datepicker: () => import("./DatePicker.vue"),
    comments: () => import("./Comment.vue"),
    cursorselect: () => import("./CursorSelect.vue"),
    timeline: () => import("./Timeline.vue"),
  },
  props: {
    cmd: Object,
    tiertype: Number,
    tier_type_prix: Array,
    qte_prix: Array,
    editer: Boolean,
    amender: Boolean,
    loading: Boolean,
    valider: Boolean,
    solder: Boolean,
    modes: Array,
    modalites: Array,
    currencies: Array,
    ports: Array,
    units: Array,
    commandes: Array,
    tiers_agences: Array,
    products_categories: Array,
    tiers_categories: Array,
    edition: { type: Boolean, default: false },
    ipg: { type: Number, default: 10 },
  },
  data() {
    return {
      save_disable: false,
      allincotermes: [
        "FAS",
        "CFR",
        "FOB",
        "FCA",
        "EXW",
        "CPT",
        "CIP",
        "DPU",
        "DAP",
        "DDP",
        "CIF",
      ],
      modestransports: [
        { id: "1", mode: "Aérien" },
        { id: "2", mode: "Maritime" },
        { id: "3", mode: "Terrestre" },
        { id: "4", mode: "Ferroviaire" },
      ],
      message: "",
      isListClose: true,
      alert: false,
      type_alert: "error",
      drawer: null,
      modify: false,
      progress: false,
      duedate: null,
      valid: true,
      is_motif: false,
      categorie_list: [],
      numberRule: (v) => {
        if (!v || (!isNaN(parseFloat(v)) && v >= 0 && v <= 100)) return true;
        return "Nombre doit être entre 0 and 100";
      },
      act_save: true,
      isDetailClosed: true,
      isProdClosed: true,
      isDocListClose: true,
      snackbar: false,
      snackbar_timeout: 5000,
      snackbar_text: "",
      snackbar_color: "primary",
      drawer2: null,
      client: "Client information",
      mc: 200,
      kdd: 400,
      dkey: 100,
      pkey: 300,
      fdocl: 1000,
      fl: 1200,
      cmddetail: {},
      isOrderClosed: true,
      order: {},
      price_edit: false,
      edit_tier: false,
      consult_tier: false,
      changed: false,
      korder: 0,
      product: {},
      reg_type: false,
      livs: [],
      livs_select: [],
      products_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          align: "center",
          width: 10,
        },
        {
          text: "Code",
          value: "code",
          selected: true,
          slot: "href",
          align: "left",
        },
        {
          text: "Ref.",
          value: "ref",
          selected: true,
          align: "left",
        },
        {
          text: "Produit",
          value: "label",
          slot: "href99",
          column: "produit_id",
          selected: true,
          width: "300",
          description: "description",
          align: "left",
        },
        {
          text: "PU HT",
          value: "pu",
          width: "120px",
          selected: true,
        },
        {
          text: "Quantité",
          value: "qte",
          slot: "cur",
          align: "end",
          width: "120px",
          hiden: true,
          selected: true,
        },
        {
          text: "Unite",
          value: "unit",
          hiden: true,
          selected: true,
        },
        {
          text: "Montant HT",
          value: "ht",
          slot: "cur",
          align: "end",
          width: "180px",
          hiden: true,
          selected: true,
        },
        {
          text: "Avant le",
          value: "date_limite",
          selected: false,
          slot: "date",
        },
        {
          text: "Livraison",
          value: "adresse",
          hiden: true,
          selected: false,
        },
        {
          text: "Comment",
          value: "comment",
          selected: false,
        },
        {
          text: "",
          slot: "actions",
          selected: true,
          column: "produit_id",
          hiden: true,
        },
      ],
      edit: false,
      editedItem: {},
      cmd_list: [],
      cnv_list: [],
      kdoc: 700,
      klist: 500,
      cmpt: 0,
      tier_products: [],
      modes_list: [],
      avenantsheaders: [
        {
          text: "No",
          value: "no",
          selected: true,
          totlib: true,
        },

        {
          text: "Type",
          value: "contrat_type",
          selected: true,
        },
        {
          text: "Reference",
          value: "nocmd",
          selected: true,
        },
        {
          text: "Date",
          value: "sign_date",
          slot: "date",
          selected: true,
        },
      ],
      defaultItem: {
        id: -1,
        status_name: "Brouillon",
        tier: {},
        statut_id: "6",
        cmddetails: [],
        regdetails: [],
        livraisons: [],
        docs: [],
        tot_liv: 0,
        notes: "",
        c_type: "0",
        date_cmd: null,
        sign_date: null,
        can_update: true,
      },
    };
  },

  async created() {
    if (this.cmd.id) {
      this.edit = this.edition;
      if (this.cmd.id > 0) {
        this.editedItem = Object.assign({}, this.cmd);
        if (this.editedItem.statut_id == "9") {
          this.message = this.editedItem.motif ? this.editedItem.motif : "";
          this.alert = true;
        }
        this.editedItem.isprivate = this.editedItem.isprivate == 1;
        this.editedItem.price_modif = this.editedItem.price_modif == 1;
        this.editedItem.caution_av = this.editedItem.caution_av == 1;
        this.editedItem.caution_pfe = this.editedItem.caution_pfe == 1;
        this.editedItem.caution_pr = this.editedItem.caution_pr == 1;
        this.editedItem.exe_tva = this.editedItem.exe_tva == 1;
        this.addDays();
        this.get_categories();
        this.reg_type = this.editedItem.reg_type == "1";
        if (this.editedItem.tier)
          this.livs = this.editedItem.tier.livraisons
            ? this.editedItem.tier.livraisons
            : [];
        if (this.editedItem.livraisons)
          this.livs_select = this.editedItem.livraisons.map((elm) => elm.id);
        await this.get_products_tier();
        if (this.editedItem.contrat_id && this.tiertype <= 2) {
          let i = this.commandes.findIndex(
            (elm) => elm.id == this.editedItem.contrat_id
          );
          if (i >= 0) {
            this.cnv_list = this.commandes[i].cmddetails;
            this.price_edit = this.commandes[i].price_modif == 1;
          }
        }
      } else {
        this.defaultItem.date_cmd = this.$store.state.today;
        this.defaultItem.sign_date = this.$store.state.today;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.source_id = source;
        if (this.reg_type)
          this.editedItem.notes = "Bon de Commande régularisation";
        //this.editedItem.sign_date = new Date().toISOString().slice(0, 10);
        if (this.$store.state.options[0])
          this.editedItem.tol_qte =
            this.$store.state.options[0].tol_qte_cmd_plus;
        this.editedItem.tol_rcpt = 25;
        if (this.cmd.tier_id > 0) {
          this.editedItem.tier_id = this.cmd.tier_id;
        }
      }
    }
    if (this.editedItem.statut_id != "6") this.products_headers[3].slot = null;
    this.products_headers[11].hiden =
      this.editedItem.statut_id != "6" || this.reg_type;
    this.products_headers[4].text =
      (this.editedItem.country_id == 13 ? "PU HT" : "PU ") +
      "(" +
      this.editedItem.devise +
      ")";
    this.products_headers[5].hiden = this.tiertype >= 5;
    this.products_headers[6].hiden = this.tiertype >= 5;
    this.products_headers[7].hiden = this.tiertype >= 5;
    this.products_headers[8].hiden = this.tiertype >= 5;
    this.modify = this.editer && this.editedItem.statut_id == "6";
    this.edit_tier =
      (this.tiertype == 0 &&
        this.$store.state.auth.findIndex((elm) => elm == "02001") != -1) ||
      ((this.tiertype == 1 || this.tiertype == 3 || this.tiertype == 5) &&
        this.$store.state.auth.findIndex((elm) => elm == "02030") != -1) ||
      ((this.tiertype == 2 || this.tiertype == 4 || this.tiertype == 6) &&
        this.$store.state.auth.findIndex((elm) => elm == "02029") != -1) ||
      this.$store.state.isadmin;
    this.consult_tier =
      (this.tiertype == 0 && this.$store.state.auth.includes("01001")) ||
      ((this.tiertype == 1 || this.tiertype == 3 || this.tiertype == 5) &&
        this.$store.state.auth.includes("01030")) ||
      ((this.tiertype == 2 || this.tiertype == 4 || this.tiertype == 6) &&
        this.$store.state.auth.includes("01029")) ||
      this.$store.state.isadmin;
  },
  methods: {
    send_option(item) {
      this.$emit("options", item);
    },
    gotier() {
      if (this.tiertype == 1)
        this.$router.push({
          name: "clients",
          params: {
            cmd: this.cmd,
            tier_id: this.editedItem.tier_id,
            code_tier: this.editedItem.code_tier,
            tier_name: this.editedItem.tier_name,
            name: "commandes_c",
          },
        });
      if (this.tiertype == 2)
        this.$router.push({
          name: "fournisseurs",
          params: {
            cmd: this.cmd,
            tier_id: this.editedItem.tier_id,
            code_tier: this.editedItem.code_tier,
            tier_name: this.editedItem.tier_name,
            name: "commandes_f",
          },
        });
    },

    newtier() {
      if (this.tiertype == 1)
        this.$router.push({
          name: "clients",
          params: {
            cmd: this.cmd,
            tier: -1,
            name: "commandes_c",
          },
        });
      if (this.tiertype == 2)
        this.$router.push({
          name: "fournisseurs",
          params: {
            cmd: this.cmd,
            tier: -1,
            name: "commandes_f",
          },
        });
    },
    goto_proforma() {
      this.$router.push({
        name: "proformas_c",
        params: {
          cmd: this.cmd,
          pfm_id: this.editedItem.pfm_id,
          name: "commandes_c",
          filtre: this.editedItem.nopfm,
        },
      });
    },
    async get_products_tier() {
      if (this.cmd.tier_id > 0) {
        if (this.tiertype == 1 || this.tiertype == 3 || this.tiertype == 5) {
          this.tier_products = this.client_products.filter(
            (elm) => elm.statut_id == 2
          );
        }
        if (this.tiertype == 2 || this.tiertype == 4 || this.tiertype == 6) {
          //all articles
          if (this.editedItem.source_id == "0") {
            this.tier_products = this.articles.filter(
              (elm) => elm.statut_id == 2
            );
          }
          if (this.editedItem.source_id == "1") {
            let v = {
              Reste: 1,
              tier_id: this.cmd.tier_id,
            };
            let r = await this.requette(ALLDEMANDES_DETAIL, v);
            if (r)
              this.tier_products = r.alldemandesdetail.filter(
                (elm) => elm.articles.length > 0
              );
          }
        }
      }
    },

    sortBy(prop) {
      return function (a, b) {
        return a[prop] ? a[prop].localeCompare(b[prop]) : a[prop];
      };
    },
    contrat_select() {
      let i = this.commandes.findIndex(
        (elm) => elm.id == this.editedItem.contrat_id
      );
      if (i >= 0) {
        this.editedItem.tier_id = this.commandes[i].tier_id;
        this.editedItem.modepaiement = this.commandes[i].modepaiement;
        this.editedItem.isprivate = this.commandes[i].isprivate == 1;
        this.editedItem.price_modif = this.commandes[i].price_modif == 1;
        this.editedItem.caution_av = this.commandes[i].caution_av;
        this.editedItem.caution_bfe = this.commandes[i].caution_bfe;
        this.editedItem.caution_pr = this.commandes[i].caution_pr;
        this.editedItem.pr = this.commandes[i].pr;
        this.editedItem.date_pr = this.commandes[i].date_pr;
        this.editedItem.modalite_id = this.commandes[i].modalite_id;
        this.editedItem.code_tier = this.commandes[i].code_tier;
        this.editedItem.currency_id = this.commandes[i].currency_id;
        this.editedItem.country_id = this.commandes[i].country_id;
        this.editedItem.exe_tva = this.commandes[i].exe_tva;
        this.editedItem.tier_categorie_id = this.commandes[i].tier_categorie_id;
        this.editedItem.devise = this.commandes[i].devise;
        this.editedItem.modetransport = this.commandes[i].modetransport;
        this.editedItem.incoterme = this.commandes[i].incoterme;
        this.editedItem.mont_transport = this.commandes[i].mont_transport;
        this.editedItem.port_id = this.commandes[i].port_id;
        this.editedItem.tol_qte = this.commandes[i].tol_qte;
        this.editedItem.tol_rcpt = this.commandes[i].tol_rcpt;
        if (this.tiertype >= 3) this.editedItem.delai = this.commandes[i].delai;
        this.editedItem.cmddetails = this.commandes[i].cmddetails;
        this.modes_list = this.modes.filter(
          (elm) =>
            elm.type ==
            (this.editedItem.country_id == 13 || !this.editedItem.country_id
              ? 0
              : 1)
        );
      }
    },
    get_categories() {
      this.categorie_list = [];
      if (this.tiers_categories) {
        this.categorie_list = this.tiers_categories.filter(
          (elm) => elm.tier_id == this.editedItem.tier_id
        );

        if (this.categorie_list.length == 1)
          this.editedItem.tier_categorie_id =
            this.categorie_list[0].categorie_id;
      }
    },
    async save_contrat_base(cmd) {
      let v = {
        cmd: {
          statut_id: 8,
          cmd_type: cmd.cmd_type,
          date_cmd: cmd.date_cmd,
          write_uid: this.$store.state.me.id,
          tier_id: cmd.tier_id,
          comment: cmd.comment,
          notes: cmd.notes,
          cmd_ref: cmd.cmd_ref,
          source_id: cmd.source_id,
          tol_qte: parseFloat(cmd.tol_qte),
          tol_rcpt: parseFloat(cmd.tol_rcpt),
          label: cmd.label,
          exe_tva: cmd.exe_tva,
          garantie: parseFloat(cmd.garantie),
          avance: parseFloat(cmd.avance),
          centrale: cmd.centrale,
          currency_id: cmd.currency_id,

          mont_ht: cmd.mont_ht,
          mont_tva: cmd.mont_tva,
          mont_ttc: cmd.mont_ttc,

          port_id: cmd.port_id,
          incoterme: cmd.incoterme,
          mont_transport: parseFloat(cmd.mont_transport),
          modetransport: cmd.modetransport,

          delai: parseInt(cmd.delai),
          modalite_id: cmd.modalite_id,
          modepaiement: cmd.modepaiement,

          date_pr: cmd.date_pr,
          isprivate: cmd.isprivate,
          price_modif: cmd.price_modif,
          caution_av: cmd.caution_av,
          caution_bfe: cmd.caution_bfe,
          caution_pr: cmd.caution_pr,
          pr: cmd.pr,

          sign_by: cmd.sign_by,
          sign_date: cmd.sign_date,
          contrat_id: this.editedItem.contrat_id,
        },
      };
      let r = await this.maj(CREATE_CMD, v);
      if (r) {
        await this.create_detail_cmd(r.createCmd.id, cmd.cmddetails);
      }
    },
    async update_contrat_base(cmd) {
      let v = {
        cmd: {
          id: cmd.id,
          tol_qte: parseFloat(this.editedItem.tol_qte),
          tol_rcpt: parseFloat(this.editedItem.tol_rcpt),
          exe_tva: this.editedItem.exe_tva ? 1 : 0,
          tier_categorie_id: this.editedItem.tier_categorie_id,
          garantie: parseFloat(this.editedItem.garantie),
          avance: parseFloat(this.editedItem.avance),
          centrale: this.editedItem.centrale ? 1 : 0,
          currency_id: this.editedItem.currency_id,

          mont_ht: this.subTotal,
          mont_tva: this.sumtaxTotal("tva"),
          mont_ttc: this.grandTotal,

          port_id: this.editedItem.port_id,
          incoterme: this.editedItem.incoterme,
          mont_transport: parseFloat(this.editedItem.mont_transport),
          modetransport: this.editedItem.modetransport,

          delai: parseInt(this.editedItem.delai),
          modalite_id: this.editedItem.modalite_id,
          modepaiement: this.editedItem.modepaiement,

          date_pr: this.editedItem.date_pr,
          isprivate: this.editedItem.isprivate ? 1 : 0,
          price_modif: this.editedItem.price_modif ? 1 : 0,
          caution_av: this.editedItem.caution_av ? 1 : 0,
          caution_bfe: this.editedItem.caution_bfe ? 1 : 0,
          caution_pr: this.editedItem.caution_pr ? 1 : 0,
          pr: parseFloat(this.editedItem.pr),

          write_uid: this.$store.state.me.id,
        },
      };
      let r = await this.maj(UPDATE_CMD, v);
      if (r) {
        this.create_detail_cmd(cmd.id, this.editedItem.cmddetails);
      }
    },
    async create_detail_cmd(cmd_id, cmddetails) {
      // créer detail commande
      let l = [];
      for (let index = 0; index < cmddetails.length; index++) {
        const element = cmddetails[index];
        l.push({
          pu: element.pu,
          qte: parseFloat(element.qte),
          cmd_id: cmd_id,
          delai: element.delai,
          adress_id: element.adress_id,
          produit_id: element.produit_id,
          article_id: element.article_id,
          fk_unit: element.fk_unit,
          gamme_id: element.gamme_id,
          tva_tx: parseFloat(element.tva_tx),
          create_uid: this.$store.state.me.id,
          write_uid: this.$store.state.me.id,
        });
      }
      let v = { input: l, cmd_id: cmd_id };
      await this.maj(CREATE_CMD_DETAIL, v);
    },
    formatDate(date) {
      if (!date) return null;
      let d = new Date(date).toISOString().substr(0, 10);
      const [year, month, day] = d.split("-");
      return `${day}/${month}/${year}`;
    },

    async updatePrixAchat(valid) {
      let articles_id = [];
      let articles_list = null;

      this.detail_list.forEach((element) => {
        if (element.article_id) articles_id.push(element.article_id);
        else {
          element.articles.forEach((elm) => {
            articles_id.push(elm.id);
          });
        }
      });

      if (valid == 1) {
        articles_list = [];
        this.detail_list.forEach((element) => {
          if (element.article_id)
            articles_list.push({
              id: element.article_id,
              prixachat: element.pu,
              date_dern_achat: this.editedItem.date_cmd,
            });
          else {
            element.articles.forEach((elm) => {
              articles_list.push({
                id: elm.article_id,
                prixachat: element.pu,
                date_dern_achat: this.editedItem.date_cmd,
              });
            });
          }
        });
      }

      let v = {
        articles_id: articles_id,
        articles_list: articles_list,
        valid: valid,
        write_uid: this.$store.state.me.id,
      };
      await this.maj(UPDATE_PRIX_ACHAT, v);
    },
    async updateMvmCmd(areg) {
      let v = {
        mvm_list: this.livs_select,
        cmd_id: this.editedItem.id,
        areg: areg,
        write_uid: this.$store.state.me.id,
      };
      await this.maj(UPDATE_MVM_CMD, v);
    },
    async verif_modif(e) {
      this.alert = false;
      let ok = false;
      let v = {
        table: "commandes",
        statut_id: "6",
        id: this.editedItem.id,
      };
      this.progress = true;
      let r = await this.maj(UPDATE_MODIF, v);
      this.progress = false;
      if (r) {
        let d = JSON.parse(r.UpdateModif);
        if (d.modif == 1) {
          this.alert = true;
          this.message =
            "Document ouvert en modification par " +
            d.name +
            " depuis le " +
            datefr(d.modif_date, true);
          this.type_alert = "warning";
        } else if (d.modif == 2 && e == "m") {
          this.alert = true;
          this.message = "Document Validé!! Actualiser vos données ";
          this.type_alert = "error";
        } else if (d.modif == -1) {
          this.alert = true;
          this.message = "Document introuvable ";
          this.type_alert = "error";
        } else {
          this.$store.dispatch("Document", v);
          this.$store.dispatch("Editing", true);
          ok = true;
        }
      }
      this.save_disable = false;
      return ok;
    },
    async modifier() {
      if (await this.verif_modif("m")) {
        this.edit = true;
        this.$emit("edition", true);
        this.kdoc++;
      }
      this.save_disable = false;
    },
    async cancel() {
      let ok = true;
      if (
        (this.cmpt > 0 && this.editedItem.id < 0) ||
        (this.cmpt > 1 && this.editedItem.id > 0)
      ) {
        ok = false;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Modification en-cours! Etês-vous sûr de vouloir annuler?"
          )
        ) {
          ok = true;
        }
      }
      if (ok) {
        if (this.editedItem.id < 0) {
          this.$emit("cancel", this.editedItem);
          this.editedItem.id = null;
        }

        await this.update_modif();
        this.edit = false;
        this.$emit("edition", false);
        this.$store.dispatch("Changed", true);
        this.kdoc++;
      }
    },
    async deleteitem(item) {
      if (await this.verif_modif("d")) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir supprimmer cette ligne?",
            { color: "orange darken-3" }
          )
        ) {
          this.save_disable = true;
          let r = await this.maj(DELETE_PRODUIT, { id: item.id });
          if (r) {
            this.detail_list.splice(item.index, 1);
            this.$store.dispatch("Changed", true);
            this.$store.dispatch("Editing", false);
            this.$emit("delete");
            this.update_modif();
            this.kdoc++;
          }
        } else this.update_modif();
      }
      this.save_disable = false;
    },
    tierChange(item, l) {
      let list = [];
      if (l) list = l;
      this.editedItem.tier_id = item;
      let i = list.findIndex((elm) => elm.id == this.editedItem.tier_id);
      this.livs = [];
      this.livs_select = [];
      this.editedItem.tier = {};
      this.editedItem.modepaiement = null;
      this.editedItem.date_pr = null;
      this.editedItem.caution_av = null;
      this.editedItem.caution_bfe = null;
      this.editedItem.caution_pr = null;
      this.editedItem.pr = null;
      this.editedItem.modalite_id = null;
      this.editedItem.code_tier = null;
      this.editedItem.currency_id = null;
      this.editedItem.country_id = null;
      this.editedItem.exe_tva = false;
      this.editedItem.tier_categorie_id = null;
      this.editedItem.tier.adresse = null;
      this.editedItem.tier.RC = null;
      this.editedItem.tier.ART = null;
      this.editedItem.tier.NIF = null;
      this.editedItem.tier.cmpt = null;
      this.editedItem.tier.statut_id = null;
      this.editedItem.devise = null;
      this.get_categories();
      if (i >= 0) {
        this.livs = list[i].livraisons;
        if (this.livs.length > 0 && this.tiertype <= 2) {
          this.reg_type = true;
          this.livs.forEach((element) => {
            this.livs_select.push(element.id);
          });
        }
        this.editedItem.code_tier =
          this.tiertype == 1 || this.tiertype == 3 || this.tiertype == 5
            ? list[i].code_client
            : list[i].code_fournisseur;
        this.editedItem.tier.adresse = list[i].adresse;
        this.editedItem.tier.RC = list[i].RC;
        this.editedItem.tier.ART = list[i].ART;
        this.editedItem.tier.NIF = list[i].NIF;
        this.editedItem.tier.cmpt = list[i].cmpt;
        this.editedItem.currency_id = list[i].currency_id;
        this.editedItem.country_id = list[i].country_id;
        this.modes_list = this.modes.filter(
          (elm) =>
            elm.type ==
            (this.editedItem.country_id == 13 || !this.editedItem.country_id
              ? 0
              : 1)
        );
        this.editedItem.tier.statut_id = list[i].statut_id;
        this.editedItem.modepaiement = list[i].modepaiement;
        this.editedItem.modalite_id = list[i].modalite_id;
        this.editedItem.exe_tva = list[i].exe_tva == "1";
        this.editedItem.devise = list[i].devise;
      }
      if (this.agences_tier.length == 1)
        this.editedItem.bank_id = this.agences_tier[0].id;
      else {
        let i = this.agences_tier.findIndex((elm) => elm.default_agence == 1);
        if (i >= 0) this.editedItem.bank_id = this.agences_tier[i].id;
      }
      this.cmpt++;
    },
    nbjour() {
      if (this.editedItem.sign_date && this.duedate) {
        var date2 = new Date(this.duedate);
        var date1 = new Date(this.editedItem.sign_date);
        var diff = date2.getTime() - date1.getTime();

        if (this.tiertype <= 4)
          this.editedItem.delai = Math.floor(diff / (24 * 3600 * 1000));
        else
          this.editedItem.delai =
            date2.getMonth() -
            date1.getMonth() +
            12 * (date2.getFullYear() - date1.getFullYear());
        this.cmpt++;
      } else this.editedItem.delai = null;
    },
    addDays() {
      if (this.editedItem.delai && this.editedItem.sign_date) {
        let d = parseInt(this.editedItem.delai);
        var result = new Date(this.editedItem.sign_date);
        if (this.tiertype <= 4) result.setDate(result.getDate() + d);
        else result.setMonth(result.getMonth() + d);
        this.duedate = result.toISOString().substr(0, 10);
        this.kdd++;
      } else this.duedate = null;
      this.cmpt++;
    },
    datefr(date, time = false) {
      let t = "";
      let option = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    async OpenProductForm(item) {
      if (item.col == "code") {
        if (this.tiertype == 1)
          await this.$router
            .push({
              name: "product_tosell",
              params: { cmd: this.cmd, item: item, name: "commandes_c" },
            })
            .catch(() => {});
        if (this.tiertype == 2)
          await this.$router
            .push({
              name: "product_tobuy",
              params: { cmd: this.cmd, item: item, name: "commandes_f" },
            })
            .catch(() => {});
      } else if (item.produit_id || item.id < 0) {
        if (await this.verif_modif("m")) {
          this.product = item;
          this.product.cmd_id = this.editedItem.id;
          this.product.tier_id = this.editedItem.tier_id;
          this.dkey++;
          this.isDetailClosed = false;
        }
      } else {
        if (this.tiertype == 1)
          await this.$router
            .push({
              name: "product_tosell",
              params: { cmd: this.cmd, item: item, name: "commandes_c" },
            })
            .catch(() => {});
        else if (this.tiertype == 2)
          await this.$router
            .push({
              name: "product_tobuy",
              params: { cmd: this.cmd, item: item, name: "commandes_f" },
            })
            .catch(() => {});
        if (this.tiertype == 3)
          await this.$router
            .push({
              name: "product_tosell",
              params: { cmd: this.cmd, item: item, name: "contrats_c" },
            })
            .catch(() => {});
        else if (this.tiertype == 4)
          await this.$router
            .push({
              name: "product_tobuy",
              params: { cmd: this.cmd, item: item, name: "contrats_f" },
            })
            .catch(() => {});
        else if (this.tiertype == 5)
          await this.$router
            .push({
              name: "product_tosell",
              params: { cmd: this.cmd, item: item, name: "convention_c" },
            })
            .catch(() => {});
        else if (this.tiertype == 6)
          await this.$router
            .push({
              name: "product_tobuy",
              params: { cmd: this.cmd, item: item, name: "convention_f" },
            })
            .catch(() => {});
      }
    },
    receipt_sf() {
      if (this.tiertype == 2)
        this.$router.push({
          name: "service_fait_a",
          params: { cmd: this.cmd, name: "commandes_f" },
        });

      if (this.tiertype == 4)
        this.$router.push({
          name: "service_fait_v",
          params: { cmd: this.cmd, name: "contrats_f" },
        });
    },
    receipt() {
      if (this.tiertype == 1)
        this.$router.push({
          name: "livraisons",
          params: { cmd: this.cmd, name: "commandes_c" },
        });
      if (this.tiertype == 2)
        this.$router.push({
          name: "receptions_e",
          params: { cmd: this.cmd, name: "commandes_f" },
        });
      if (this.tiertype == 3)
        this.$router.push({
          name: "livraisons",
          params: { cmd: this.cmd, name: "contrats_c" },
        });
      if (this.tiertype == 4)
        this.$router.push({
          name: "receptions_e",
          params: { cmd: this.cmd, name: "contrats_f" },
        });
    },
    OpenDocList() {
      this.isDocListClose = false;
      this.fdocl++;
    },
    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    get_motif(m) {
      this.editedItem.motif = m;
    },
    async solder_cmd() {
      if (await this.verif_modif("s")) {
        this.save_disable = true;
        let ok = true;
        if (this.cmd.nbof > 0) {
          this.message =
            this.cmd.nbof + " Ordre(s) de fabrication en cours d'execution !!";
          this.type_alert = "error";
          this.alert = true;
          this.$store.dispatch("Editing", false);
          ok = false;
        }
        if (ok) {
          this.is_motif = true;
          if (
            await this.$refs.confirm.open(
              "Confirmation",
              "Etês-vous sûr de vouloir annuler " + this.title + "?",
              { color: "orange darken-3" }
            )
          ) {
            this.editedItem.statut_id = 9;
            let v = {
              cmd: {
                id: this.editedItem.id,
                statut_id: this.editedItem.statut_id,
                motif: this.editedItem.motif,
              },
            };
            let r = await this.maj(UPDATE_CMD, v, true);
            if (r) {
              this.$store.dispatch("Editing", false);
              v = {
                cmd_id: this.editedItem.id,
                write_uid: this.$store.state.me.id,
              };
              await this.maj(SOLDER_ORDER, v);
            }
          } else this.update_modif();
          this.is_motif = false;
        }
      }
      this.save_disable = false;
    },
    async requette(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.progress = false;
        });
      return r;
    },
    async unlock() {
      this.is_motif = true;

      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir déverrouiller " + this.title + "?"
        )
      ) {
        let ok = await this.update_statut(6, this.editedItem.motif);

        if (ok) {
          this.editedItem.statut_id = 6;
          if (this.reg_type) await this.updateMvmCmd(1);
          if (this.tiertype == 2) this.updatePrixAchat(0);
        }
        this.$store.dispatch("Editing", false);
      }
      this.is_motif = false;
    },
    async update_statut(st, motif) {
      let v = {
        cmd: {
          id: this.editedItem.id,
          statut_id: st,
          motif: motif,
        },
      };
      this.save_disable = true;
      let ok = await this.maj(UPDATE_CMD, v, true);
      this.save_disable = false;
      return ok;
    },
    async valider_cmd() {
      if (await this.verif_modif("m")) {
        let ok = true;
        this.save_disable = true;
        if (this.detail_list.find((elm) => elm.produit_id == null)) {
          this.message =
            "Un nouveau produit doit être créer!!-validation impossible";
          this.type_alert = "error";
          this.alert = true;
          ok = false;
          this.update_modif();
        }
        //validation avenant
        if (this.tiertype > 2 && this.editedItem.c_type == "1") {
          //verifier changement
          let i = this.commandes.findIndex(
            (elm) => elm.id == this.editedItem.contrat_id
          );
          if (i >= 0) {
            //maitre
            ok =
              this.editedItem.modepaiement != this.commandes[i].modepaiement ||
              this.editedItem.modalite_id != this.commandes[i].modalite_id ||
              this.editedItem.currency_id != this.commandes[i].currency_id ||
              this.editedItem.exe_tva != this.commandes[i].exe_tva ||
              this.editedItem.modetransport !=
                this.commandes[i].modetransport ||
              this.editedItem.incoterme != this.commandes[i].incoterme ||
              this.editedItem.port_id != this.commandes[i].port_id ||
              this.editedItem.tol_qte != this.commandes[i].tol_qte ||
              this.editedItem.tol_rcpt != this.commandes[i].tol_rcpt ||
              this.editedItem.date_pr != this.commandes[i].date_pr ||
              this.editedItem.caution_av != this.commandes[i].caution_av ||
              this.editedItem.caution_bfe != this.commandes[i].caution_bfe ||
              this.editedItem.caution_pr != this.commandes[i].caution_pr ||
              this.editedItem.pr != this.commandes[i].pr ||
              this.editedItem.delai != this.commandes[i].delai;
            if (!ok) {
              //verifier  nombre d'article
              ok =
                this.editedItem.cmddetails.length !=
                this.commandes[i].cmddetails.length;

              if (!ok)
                // verifier qte, prix ou delai si changement
                for (
                  let index = 0;
                  index < this.commandes[i].cmddetails.length;
                  index++
                ) {
                  const element = this.commandes[i].cmddetails[index];
                  let j = this.editedItem.cmddetails.findIndex(
                    (elm) =>
                      elm.produit_id == element.produit_id &&
                      elm.article_id == element.article_id
                  );

                  if (j == -1) {
                    ok = true;
                    break;
                  } else {
                    if (
                      this.editedItem.cmddetails[j].qte != element.qte ||
                      this.editedItem.cmddetails[j].pu != element.pu ||
                      this.editedItem.cmddetails[j].delai != element.delai
                    ) {
                      ok = true;
                      break;
                    }
                  }
                }
            }
          }
          if (!ok) {
            this.message =
              "Aucun changement par rapport au contrat en cours!!-validation impossible";
            this.type_alert = "error";
            this.alert = true;
            this.update_modif();
          }
          //verifier quantite deja livrée ou importée
          // if (ok && !this.reg_type) {
          //   let qte_min;
          //   let label;
          //   let u;
          //   for (
          //     let index = 0;
          //     index <
          //     this.commandes[i].cmddetails.filter(
          //       (elm) => elm.qte_liv > 0 || elm.qte_import > 0
          //     ).length;
          //     index++
          //   ) {
          //     const element = this.commandes[i].cmddetails[index];
          //     let j = this.editedItem.cmddetails.findIndex(
          //       (elm) =>
          //         elm.produit_id == element.produit_id &&
          //         elm.article_id == element.article_id
          //     );
          //     label = element.label;
          //     qte_min =
          //       element.qte_liv > element.qte_import
          //         ? element.qte_liv
          //         : element.qte_import;
          //     u = element.unit;
          //     if (j == -1) {
          //       ok = false;
          //       break;
          //     } else {
          //       if (this.editedItem.cmddetails[j].qte < qte_min) {
          //         ok = false;
          //         break;
          //       }
          //     }
          //   }
          //   if (!ok) {
          //     this.message =
          //       "la quantité de " +
          //       qte_min +
          //       " " +
          //       u +
          //       " de l'article " +
          //       label +
          //       " est déja livrée!!-validation impossible";
          //     this.type_alert = "error";
          //     this.alert = true;
          //     this.update_modif();
          //   }
          // }
        }
        this.$store.dispatch("Editing", false);
        if (ok) {
          if (
            await this.$refs.confirm.open(
              "Confirmation",
              "Etês-vous sûr de vouloir valider " + this.title + "?"
            )
          ) {
            let st = 7;

            //si avenant
            if (this.tiertype > 2 && this.editedItem.c_type == "1") {
              let i = this.commandes.findIndex(
                (elm) => elm.id == this.editedItem.contrat_id
              );
              if (i >= 0) {
                let cmd = this.commandes[i];

                //si le 1er avenant save contrat de base
                if (this.editedItem.base_contrat.avenants.length == 1) {
                  this.editedItem.nocmd = cmd.nocmd + "-01";
                  await this.save_contrat_base(cmd);
                } else
                  this.editedItem.nocmd =
                    cmd.nocmd +
                    "-0" +
                    parseInt(this.editedItem.base_contrat.avenants.length) -
                    1;
                //update contrat de base
                await this.update_contrat_base(cmd);
                st = 8;
              }
            }
            let ok = await this.update_statut(st);

            if (ok) {
              this.editedItem.statut_id = st;
              if (this.reg_type) await this.updateMvmCmd(0);
              if (this.tiertype == 2) this.updatePrixAchat(1);
              this.$store.dispatch("Editing", false);
              //si issue from proforma
              if (this.editedItem.pfm_id) {
                let v = {
                  input: {
                    id: this.editedItem.pfm_id,
                    statut_id: 5,
                  },
                };
                await this.maj(UPDATE_PFM, v);
              }
            }
          } else this.update_modif();
        }
      }
      this.save_disable = false;
    },
    async update_modif() {
      this.$store.dispatch("Editing", false);
      if (this.editedItem.id > 0) {
        this.save_disable = true;
        let v = {
          cmd: {
            id: this.editedItem.id,
            modif_by: null,
            modif_date: null,
          },
        };
        await this.maj(UPDATE_CMD, v);
      }

      this.save_disable = false;
    },
    closeDetailForm() {
      this.update_modif();
      this.isDetailClosed = true;
    },

    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    async amender_cmd() {
      this.is_motif = true;
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir amender cette commande?",
          { color: "orange darken-3" }
        )
      ) {
        this.save_disable = true;
        let v;
        // créer commande
        v = {
          cmd: {
            cmd_type: this.tiertype,
            c_type: this.cmd.c_type,
            tier_id: this.cmd.tier_id,
            date_cmd: this.$store.state.today,
            statut_id: 6,
            comment: this.cmd.comment,
            parent_id: this.cmd.id,
            notes: "Annule et remplace la commande N° " + this.cmd.nocmd,
            bank_id: this.cmd.bank_id,
            cmd_ref: this.cmd.cmd_ref,
            source_id: this.cmd.source_id,
            tol_qte: parseFloat(this.cmd.tol_qte),
            tol_rcpt: parseFloat(this.cmd.tol_rcpt),
            label: this.cmd.label,
            exe_tva: this.cmd.exe_tva ? 1 : 0,
            tier_categorie_id: this.cmd.tier_categorie_id,
            garantie: parseFloat(this.cmd.garantie),
            avance: parseFloat(this.cmd.avance),
            centrale: this.cmd.centrale ? 1 : 0,
            currency_id: this.cmd.currency_id,
            reg_type: this.reg_type ? 1 : 0,

            port_id: this.cmd.port_id,
            incoterme: this.cmd.incoterme,
            mont_transport: parseFloat(this.cmd.mont_transport),

            modetransport: this.cmd.modetransport,

            delai: parseInt(this.cmd.delai),
            modalite_id: this.cmd.modalite_id,
            modepaiement: this.cmd.modepaiement,
            pfm_id: this.cmd.pfm_id,

            date_pr: this.cmd.date_pr,
            isprivate: this.cmd.isprivate ? 1 : 0,
            price_modif: this.cmd.price_modif ? 1 : 0,
            caution_av: this.cmd.caution_av ? 1 : 0,
            caution_bfe: this.cmd.caution_bfe ? 1 : 0,
            caution_pr: this.cmd.caution_pr ? 1 : 0,
            pr: parseFloat(this.cmd.pr),

            sign_by: this.cmd.sign_by,
            sign_date: this.cmd.sign_date,

            create_uid: parseInt(this.$store.state.me.id),
            write_uid: parseInt(this.$store.state.me.id),
          },
        };
        let r = await this.maj(CREATE_CMD, v);
        if (r) {
          let cmd = r.createCmd;
          // créer detail commande
          let l = [];
          for (let index = 0; index < this.detail_list.length; index++) {
            const element = this.detail_list[index];
            l.push({
              pu: element.pu,
              qte: parseFloat(element.qte),
              cmd_id: cmd.id,
              delai: element.delai,
              adress_id: element.adress_id,
              produit_id: element.produit_id,
              article_id: element.article_id,
              fk_unit: element.fk_unit,
              gamme_id: element.gamme_id,
              tva_tx: parseFloat(element.tva_tx),
              create_uid: this.$store.state.me.id,
              write_uid: this.$store.state.me.id,
            });
          }
          let new_comments = [];
          if (this.comments.length > 0) {
            this.comments.forEach((element) => {
              new_comments.push({
                document_id: parseInt(cmd.id),
                document_type: 2,
                comment_type: parseInt(element.comment_type),
                comment: element.comment,
                created_at: element.created_at,
                create_uid: element.create_uid,
              });
            });
            new_comments.push({
              document_id: parseInt(cmd.id),
              document_type: 2,
              comment_type: 4,
              comment:
                "------Fin Notes Commande N° " +
                this.cmd.nocmd +
                "------------",
              created_at: new Date().toISOString().slice(0, 19),
              create_uid: this.$store.state.me.id,
            });
          }

          v = {
            input: l,
            cmd_id: cmd.id,
            comments: new_comments.length > 0 ? new_comments : null,
          };
          let r1 = await this.maj(CREATE_CMD_DETAIL, v);
          if (r1) {
            let v = {
              cmd: {
                id: this.editedItem.id,
                statut_id: 9,
                motif:
                  "Annulée et remplacée par la commande N° " +
                  cmd.nocmd +
                  "\n Motif :" +
                  this.editedItem.motif,
                pfm_id: null,
              },
            };
            this.save_disable = true;
            let ok = await this.maj(UPDATE_CMD, v, true);

            if (ok) this.editedItem.statut_id = 9;
          }
          this.$store.dispatch("Changed", true);
          this.$emit("change", cmd);
        }
      }
      this.save_disable = false;
    },
    async deleteCmd() {
      if (this.editedItem.id < 0) {
        this.editedItem.id = null;
        this.$emit("cancel", this.editedItem);
      } else if (await this.verif_modif("m")) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir supprimer " + this.title + "?"
          )
        ) {
          this.save_disable = true;
          let v = {
            id: this.editedItem.id,
          };
          let r = await this.requette(DELETE_CMD, v);
          if (r) {
            this.$store.dispatch("Editing", false);
            //si issue from proforma
            if (this.editedItem.pfm_id) {
              v = {
                input: {
                  id: this.editedItem.pfm_id,
                  statut_id: 3,
                  motif: "Commande supprimée",
                },
              };
              await this.maj(UPDATE_PFM, v);
            }
            this.editedItem = { id: null };
            this.$store.dispatch("Changed", true);
          }
        } else this.update_modif();
      }
      this.save_disable = false;
    },
    decimalDigits: function (value) {
      return typeof value == "number" ? value.toFixed(2) : 0;
    },
    async printCmd() {
      let doctype = [];
      doctype.push({
        lib: {
          content: "Bon de Commande",
          styles: {
            fontStyle: "bold",
            fontSize: 20,
            textColor: [0, 0, 0],
            cellPadding: { bottom: 3 },
          },
          colSpan: 2,
        },
      });
      doctype.push({
        lib: {
          content: "N° :",
          styles: { fontStyle: "normal", fontSize: 10 },
        },
        val: {
          content: this.editedItem.nocmd,
          styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Date : ",
          styles: { fontStyle: "normal", fontSize: 10 },
        },
        val: {
          content: datefr(this.editedItem.sign_date),
          styles: { fontStyle: "normal", fontSize: 10, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Réference:",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.cmd_ref ? this.editedItem.cmd_ref : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Mode de Réglement:",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.mode ? this.mode : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      doctype.push({
        lib: {
          content: "Modalité de Paiement:",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.modalite ? this.modalite : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      if (this.tiertype <= 2 && this.editedItem.contrat_id)
        doctype.push({
          lib: {
            content: "Convention N°",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: this.editedItem.contrat ? this.editedItem.contrat : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });

      if (this.editedItem.delai)
        doctype.push({
          lib: {
            content: "Délai de Livraison:",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: this.editedItem.delai
              ? this.editedItem.delai + " Jours"
              : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
      if (this.transport)
        doctype.push({
          lib: {
            content: "Mode de Transport:",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: this.transport ? this.transport : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
      if (this.port)
        doctype.push({
          lib: {
            content: "Lieu de débarquement:",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: this.port ? this.port : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
      if (this.editedItem.incoterme)
        doctype.push({
          lib: {
            content: "Condition:",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: this.editedItem.incoterme ? this.editedItem.incoterme : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
      if (code != 1) {
        doctype.push({
          lib: {
            content: "Contact:",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: this.editedItem.contact ? this.editedItem.contact : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
        doctype.push({
          lib: {
            content: "Tél. :",
            styles: { fontStyle: "normal", fontSize: 9 },
          },
          val: {
            content: this.editedItem.contact_tel
              ? this.editedItem.contact_tel
              : "",
            styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          },
        });
      }
      let tier = [];
      tier.push({
        lib: {
          content: this.tiertype == 2 ? "Fournisseur :" : "Client :",
          styles: { fontStyle: "normal", fontSize: 12, textColor: [0, 0, 0] },
          colSpan: 2,
        },
      });
      tier.push({
        lib: {
          content: this.editedItem.tier_name,
          styles: { fontStyle: "bold", fontSize: 12, textColor: [0, 0, 0] },
          colSpan: 2,
        },
      });
      tier.push({
        lib: {
          content: this.editedItem.tier.adresse
            ? this.editedItem.tier.adresse
            : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
          colSpan: 2,
        },
      });
      tier.push({
        lib: {
          content: "Tél :",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.tier.tel ? this.editedItem.tier.tel : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      tier.push({
        lib: {
          content: "Email :",
          styles: { fontStyle: "normal", fontSize: 9 },
        },
        val: {
          content: this.editedItem.tier.email ? this.editedItem.tier.email : "",
          styles: { fontStyle: "normal", fontSize: 9, textColor: [0, 0, 0] },
        },
      });
      let foot = [];
      foot.push({
        libelle: this.editedItem.country_id == 13 ? "Montant HT" : "Montant",
        value: this.numberWithSpace(this.subTotal.toFixed(2)),
      });
      this.taxTotal.forEach((element) => {
        foot.push({
          libelle: "TVA " + element.taux + " %",
          value: this.numberWithSpace(element.tva.toFixed(2)),
        });
      });

      if (this.taxTotal.length > 1) {
        foot.push({
          libelle: "Total TVA",
          value: this.numberWithSpace(this.sumtaxTotal("tva").toFixed(2)),
        });
      }
      if (
        this.subTotal > 0 &&
        this.sumtaxTotal("tva") == 0 &&
        this.editedItem.country_id == 13
      ) {
        foot.push({
          libelle: "TVA ",
          value: "EXONORE",
        });
      }

      if (this.editedItem.mont_transport > 0) {
        foot.push({
          libelle: "Frêt",
          value: this.numberWithSpace(
            this.editedItem.mont_transport.toFixed(2)
          ),
        });
      }

      foot.push({
        libelle:
          this.editedItem.country_id == 13
            ? "Montant TTC"
            : "Montant Total (" + this.editedItem.devise + ")",
        value: {
          content: this.numberWithSpace(this.grandTotal.toFixed(2)),
          styles: { fontStyle: "bold" },
        },
      });
      if (this.editedItem.avance) {
        foot.push({});
        foot.push({
          libelle: "Avance (" + this.editedItem.avance + " %)",
          value: {
            content: this.numberWithSpace(
              ((this.editedItem.avance * this.grandTotal) / 100).toFixed(2)
            ),
          },
        });
      }
      let detailheaders = [
        {
          text: "No",
          value: "no",
          selected: true,
          align: "center",
          width: 10,
        },
        {
          text: "Ref.",
          value: "code",
          selected: true,
          align: "left",
          width: 20,
        },
        {
          text: "Désignation",
          value: "label",
          selected: true,
          align: "left",
          width: "auto",
        },
        {
          text: "Quantité",
          value: "qte",
          slot: "qte",
          width: 30,
          align: "end",
          selected: true,
        },
        {
          text: "Unité",
          value: "unit",
          selected: true,
        },
        {
          text: "PU HT",
          value: "pu",
          width: 25,
          align: "end",
          selected: true,
        },
        {
          text: "Montant HT",
          value: "ht",
          slot: "cur",
          width: 30,
          align: "end",
          selected: true,
        },
      ];
      let lettre = [];
      doc_template(
        this.$store,
        detailheaders,
        this.detail_list,
        doctype,
        tier,
        foot,
        lettre,
        this.editedItem.notes,
        "Commande"
      );
    },
    //     printCmd() {
    //       // Get HTML to print from element
    //       const prtHtml = document.getElementById("cmddoc").innerHTML;

    //       // Get all stylesheets HTML
    //       let stylesHtml = "";
    //       for (const node of [
    //         ...document.querySelectorAll('link[rel="stylesheet"], style'),
    //       ]) {
    //         stylesHtml += node.outerHTML;
    //       }

    //       // Open the print window
    //       const WinPrint = window.open(
    //         "",
    //         "",
    //         "left=200,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
    //       );

    //       WinPrint.document.write(`<!DOCTYPE html>
    // <html>
    //   <head>
    //     ${stylesHtml}
    //   </head>
    //   <body>
    //     ${prtHtml}
    //   </body>
    // </html>`);

    //       WinPrint.document.close();
    //       WinPrint.focus();
    //       WinPrint.print();
    //       WinPrint.close();
    //     },
    sumtaxTotal(key) {
      // sum data in give key (property)
      return this.taxTotal
        ? this.taxTotal.reduce((a, b) => a + (b[key] || 0), 0)
        : 0;
    },
    list_avenants() {
      if (this.avenants) this.avenants.sort(this.sortBy("sign_date"));
      this.isListClose = false;
      this.fl++;
    },
    async save() {
      if (this.$refs.form.validate()) {
        let ok = true;

        if (this.editedItem.tier.statut_id == 4) {
          ok = false;
          this.message = "erreur tier bloqué";
          this.type_alert = "error";
          this.alert = true;
        }
        if (ok) {
          this.save_disable = true;
          if (this.editedItem.id > 0) {
            let v = {
              cmd: {
                id: this.editedItem.id,
                tier_id: this.editedItem.tier_id,
                contrat_id: this.editedItem.contrat_id,
                date_cmd: this.editedItem.date_cmd,
                comment: this.editedItem.comment,
                notes: this.editedItem.notes,
                bank_id: this.editedItem.bank_id,
                statut_id: this.editedItem.statut_id,
                cmd_ref: this.editedItem.cmd_ref,
                source_id: this.editedItem.source_id,
                tol_qte: parseFloat(this.editedItem.tol_qte),
                tol_rcpt: parseFloat(this.editedItem.tol_rcpt),
                label: this.editedItem.label,
                exe_tva: this.editedItem.exe_tva ? 1 : 0,
                tier_categorie_id: this.editedItem.tier_categorie_id,
                garantie: parseFloat(this.editedItem.garantie),
                avance: parseFloat(this.editedItem.avance),
                centrale: this.editedItem.centrale ? 1 : 0,
                currency_id: this.editedItem.currency_id,
                reg_type: this.reg_type ? 1 : 0,

                port_id: this.editedItem.port_id,
                incoterme: this.editedItem.incoterme,
                mont_transport: parseFloat(this.editedItem.mont_transport),

                modetransport: this.editedItem.modetransport,

                delai: parseInt(this.editedItem.delai),
                modalite_id: this.editedItem.modalite_id,
                modepaiement: this.editedItem.modepaiement,

                date_pr: this.editedItem.date_pr,
                isprivate: this.editedItem.isprivate ? 1 : 0,
                price_modif: this.editedItem.price_modif ? 1 : 0,
                caution_av: this.editedItem.caution_av ? 1 : 0,
                caution_bfe: this.editedItem.caution_bfe ? 1 : 0,
                caution_pr: this.editedItem.caution_pr ? 1 : 0,
                pr: parseFloat(this.editedItem.pr),

                sign_by: this.editedItem.sign_by,
                sign_date: this.editedItem.sign_date,
                write_uid: this.$store.state.me.id,
              },
            };

            let r = this.maj(UPDATE_CMD, v, true);
            if (r) {
              if (this.reg_type) await this.updateMvmCmd(1);
              this.$store.dispatch("Editing", false);
            }
          } else {
            let v = {
              cmd: {
                cmd_type: this.tiertype,
                contrat_id: this.editedItem.contrat_id,
                c_type: this.editedItem.c_type,
                tier_id: this.editedItem.tier_id,
                date_cmd: this.editedItem.date_cmd,
                statut_id: this.editedItem.statut_id,
                comment: this.editedItem.comment,
                notes: this.editedItem.notes,
                bank_id: this.editedItem.bank_id,
                cmd_ref: this.editedItem.cmd_ref,
                source_id: this.editedItem.source_id,
                tol_qte: parseFloat(this.editedItem.tol_qte),
                tol_rcpt: parseFloat(this.editedItem.tol_rcpt),
                label: this.editedItem.label,
                exe_tva: this.editedItem.exe_tva ? 1 : 0,
                tier_categorie_id: this.editedItem.tier_categorie_id,
                garantie: parseFloat(this.editedItem.garantie),
                avance: parseFloat(this.editedItem.avance),
                centrale: this.editedItem.centrale ? 1 : 0,
                currency_id: this.editedItem.currency_id,
                reg_type: this.reg_type ? 1 : 0,

                port_id: this.editedItem.port_id,
                incoterme: this.editedItem.incoterme,
                mont_transport: parseFloat(this.editedItem.mont_transport),

                modetransport: this.editedItem.modetransport,

                delai: parseInt(this.editedItem.delai),
                modalite_id: this.editedItem.modalite_id,
                modepaiement: this.editedItem.modepaiement,

                date_pr: this.editedItem.date_pr,
                isprivate: this.editedItem.isprivate ? 1 : 0,
                price_modif: this.editedItem.price_modif ? 1 : 0,
                caution_av: this.editedItem.caution_av ? 1 : 0,
                caution_bfe: this.editedItem.caution_bfe ? 1 : 0,
                caution_pr: this.editedItem.caution_pr ? 1 : 0,
                pr: parseFloat(this.editedItem.pr),

                sign_by: this.editedItem.sign_by,
                sign_date: this.editedItem.sign_date,

                create_uid: parseInt(this.$store.state.me.id),
                write_uid: parseInt(this.$store.state.me.id),
              },
            };
            let r = await this.maj(CREATE_CMD, v);
            if (r) {
              this.editedItem.id = r.createCmd.id;
              if (this.tiertype > 2 && this.editedItem.c_type == "1") {
                await this.create_detail_cmd(
                  r.createCmd.id,
                  this.editedItem.cmddetails
                );
              }
              if (this.reg_type) await this.updateMvmCmd(1);

              this.$store.dispatch("Changed", true);
              this.$store.dispatch("Editing", false);
              this.$emit("change", this.editedItem);
            }
          }
          this.edit = false;
          this.$emit("edition", false);
          this.kdoc++;
        }
      }
      this.save_disable = false;
    },
    devise_change() {
      let i = this.currencies.findIndex(
        (elm) => elm.id == this.editedItem.currency_id
      );
      if (i >= 0) this.editedItem.devise = this.currencies[i].name;
    },
  },
  computed: {
    code() {
      return code;
    },
    variableadd() {
      let w = {
        TypeScope:
          this.tiertype == 1 || this.tiertype == 3 || this.tiertype == 5
            ? 2
            : 1,
        statut_id: [2, 3],
        Kind: 0,
      };
      return w;
    },
    tierscursor() {
      return TIERS_CURSOR;
    },
    //     besoins_list(){
    // this.dem_products.filter(elm=>elm.tier_id)
    //     },
    agences_tier() {
      let l = [];
      l = this.tiers_agences.filter(
        (elm) => elm.tier_id == this.editedItem.tier_id
      );
      return l;
    },
    auth() {
      let a =
        this.tiertype == 1
          ? "01006"
          : this.tiertype == 2
          ? "01005"
          : this.tiertype == 3
          ? "01077"
          : "01076";
      return a;
    },
    port() {
      let p;
      let i = this.ports.findIndex((elm) => elm.id == this.editedItem.port_id);
      if (i >= 0) p = this.ports[i].libelle;
      return p;
    },
    ports_alg() {
      let l = [];
      l = this.ports.filter((elm) => elm.fk_pays == "13");
      return l;
    },
    transport() {
      let m;
      let i = this.modestransports.findIndex(
        (elm) => elm.id == this.editedItem.modetransport
      );
      if (i >= 0) m = this.modestransports[i].mode;
      return m;
    },

    formTitle() {
      let c =
        this.tiertype <= 2
          ? "Commande"
          : this.tiertype == 5 || this.tiertype == 6
          ? "Convention"
          : this.editedItem.c_type == "1"
          ? "Avenant"
          : "Contrat";
      return this.editedItem.id < 0 || !this.editedItem.nocmd
        ? (this.tiertype <= 2 || this.tiertype == 5 || this.tiertype == 6
            ? "Nouvelle "
            : "Nouveau ") + c
        : c + " N° " + this.editedItem.nocmd;
    },
    garantie() {
      return grt ? true : false;
    },
    confidentiel() {
      return confidentiel;
    },
    logo() {
      return myLogo;
    },
    myname() {
      return myName;
    },
    mode() {
      let i = this.modes.findIndex(
        (elm) => elm.id == this.editedItem.modepaiement
      );
      let modepaiement = "";
      if (i >= 0) modepaiement = this.modes[i].mode;
      return modepaiement;
    },
    modalite() {
      let i = this.modalites.findIndex(
        (elm) => elm.id == this.editedItem.modalite_id
      );
      let modalite = "";
      if (i >= 0) modalite = this.modalites[i].libelle;
      return modalite;
    },

    myrc() {
      return myRC;
    },
    myif() {
      return myIF;
    },
    myart() {
      return myART;
    },
    comments: function () {
      return this.editedItem.comments ? this.editedItem.comments : [];
    },
    operations: function () {
      return this.editedItem.operations ? this.editedItem.operations : [];
    },
    avenants: function () {
      return this.editedItem.avenants ? this.editedItem.avenants : [];
    },
    articles: function () {
      let list = this.editedItem.articles ? this.editedItem.articles : [];
      return list;
    },
    client_products: function () {
      let list = this.editedItem.client_products
        ? this.editedItem.client_products
        : [];
      return list;
    },
    detail_list: function () {
      let list = [];
      if (this.reg_type) {
        if (this.editedItem.regdetails) {
          this.editedItem.regdetails.forEach((element) => {
            let i = list.findIndex(
              (elm) =>
                elm.article_id == element.article_id && elm.pu == element.pu
            );
            if (i >= 0) list[i].qte = list[i].qte + element.qte;
            else list.push(element);
          });
        }
      } else
        list = this.editedItem.cmddetails ? this.editedItem.cmddetails : [];
      return list;
    },

    subTotal: function () {
      var total = this.detail_list
        ? this.detail_list.reduce(function (accumulator, item) {
            return accumulator + item.pu * item.qte;
          }, 0)
        : 0;
      return total;
    },

    taxTotal: function () {
      let taxs = [];
      if (
        this.detail_list &&
        (this.editedItem.exe_tva != "1" || this.exe_tva) &&
        this.editedItem.country_id == 13
      ) {
        this.detail_list.forEach((element) => {
          if (element.tva_tx) {
            let i = taxs.findIndex((elm) => elm.taux == element.tva_tx);
            if (i >= 0)
              taxs[i].tva =
                taxs[i].tva + (element.pu * element.qte * element.tva_tx) / 100;
            else
              taxs.push({
                taux: element.tva_tx,
                tva: (element.pu * element.qte * element.tva_tx) / 100,
              });
          }
        });
      }
      return taxs;
    },

    ttc: function () {
      let taxs = 0;
      this.taxTotal.forEach((element) => {
        taxs = taxs + element.tva;
      });

      var total = this.subTotal + taxs + this.editedItem.mont_transport;
      return total;
    },
    mont_exe_taxs: function () {
      let taxs = 0;
      if (
        this.detail_list &&
        this.exe_tva &&
        this.editedItem.country_id == 13
      ) {
        this.detail_list.forEach((element) => {
          if (element.tva_tx) {
            taxs = taxs + (element.pu * element.qte * element.tva_tx) / 100;
          }
        });
      }
      return taxs;
    },
    grandTotal: function () {
      var total = this.ttc - this.mont_exe_taxs;
      return total;
    },

    title() {
      return this.tiertype <= 2
        ? " cette commande"
        : this.tiertype == 5 || this.tiertype == 6
        ? " cette convention"
        : this.editedItem.c_type == "0"
        ? " ce contrat"
        : " cet avenant";
    },
  },
};
</script>
<style scoped>
.multi-line {
  white-space: pre-line;
}
.text-green input {
  color: green !important;
}
.text-input-blue .v-text-field__slot input {
  color: #00f !important;
}
.scroll {
  width: auto;

  overflow-x: hidden;
  overflow-y: auto;
}
</style>
